import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';

import { throwError, Observable, of, timer, forkJoin } from 'rxjs';
import { tap, switchMap, catchError, finalize } from 'rxjs/operators';
import * as moment from 'moment';

import {
  ConnectedUser,
  ResourceSet,
  Resource,
  AuthUser,
  AuthMode,
  BasicResource,
  AttributeResource,
  TypeResource,
  ExpressionValidationConfig,
  ExpressionValidationResult,
  ExpressionValidationSpecification,
  ApiValidationConfig,
  RefreshToken,
  AttributesToLoad,
} from '../models/dataContract.model';

import { ConfigService } from './config.service';
import { UtilsService } from './utils.service';
import { SwapService } from './swap.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private utils: UtilsService,
    private swap: SwapService
  ) {}

  private serviceType = 'mim';
  private encryptionKey = '';
  private secret = '';
  private loginUserAttributes: string[] = [];

  /** Refresh token */
  private refreshTokens: Array<RefreshToken> = [];

  private authNMode: AuthMode = undefined;
  get authenticationMode() {
    return this.authNMode;
  }
  private version = '';
  get dataServiceVersion() {
    return this.version;
  }
  private baseUrl = '';
  get dataServiceUrl() {
    return this.baseUrl;
  }
  private language = '';
  get browserLanguage() {
    return this.language;
  }
  private connUser: ConnectedUser;
  get connectedUser() {
    return this.connUser;
  }
  private user: Resource = undefined;
  get loginUser() {
    return this.user;
  }
  private objectSchema: any;
  get schema() {
    return this.objectSchema;
  }
  private attrSchema: any = {};
  get attributeSchema() {
    return this.attrSchema;
  }
  private loaded = false;
  get isLoaded() {
    return this.loaded;
  }
  private configured = false;
  get isConfigured() {
    return this.configured;
  }
  private connection = '';
  get accessConnection() {
    return this.connection;
  }
  private token = '';
  get accessToken() {
    return this.token;
  }
  private rights: string[] = [];
  get rightSets() {
    return this.rights;
  }
  private uiSets: BasicResource[] = [];
  get viewSets() {
    return this.uiSets;
  }
  private adminUiSets: BasicResource[] = [];
  get adminViewSets() {
    return this.adminUiSets;
  }
  private standardUiSet: BasicResource = null;
  get standardViewSet() {
    return this.standardUiSet;
  }
  private primaryUiSet: BasicResource = null;
  get primaryViewSet() {
    return this.primaryUiSet;
  }
  set primaryViewSet(value: any) {
    this.primaryUiSet = value;
  }

  private customUiString = '{}';
  get customViewString() {
    return this.customUiString;
  }
  set customViewString(value: string) {
    this.customUiString = value;
  }
  private customUiSetting: any = '{}';
  get customViewSetting() {
    return this.customUiSetting;
  }
  set customViewSetting(value: any) {
    this.customUiSetting = value;
  }

  private standardUiString: string;
  get standardViewString() {
    return this.standardUiString;
  }
  set standardViewString(value: string) {
    this.standardUiString = value;
  }
  private standardUiSetting: any;
  get standardViewSetting() {
    return this.standardUiSetting;
  }
  set standardViewSetting(value: any) {
    this.standardUiSetting = value;
  }

  private primaryUiString: string;
  get primaryViewString() {
    return this.primaryUiString;
  }
  set primaryViewString(value: string) {
    this.primaryUiString = value;
  }
  private primaryUiSetting: any;
  get primaryViewSetting() {
    return this.primaryUiSetting;
  }
  set primaryViewSetting(value: any) {
    this.primaryUiSetting = value;
  }
  private primaryUiTimestamp: string;
  get primaryViewTimestamp() {
    return this.primaryUiTimestamp;
  }
  set primaryViewTimestamp(value: string) {
    this.primaryUiTimestamp = value;
  }

  private isAdminUiSet = false;
  get isAdminViewSet() {
    return this.isAdminUiSet;
  }

  private apiTimestamp = moment();
  get apiActivationTime() {
    return this.apiTimestamp;
  }
  set apiActivationTime(value: moment.Moment) {
    this.apiTimestamp = value;
  }

  private apiExpiredUrl = '';
  get apiActivationUrl() {
    return this.apiExpiredUrl;
  }
  set apiActivationUrl(value: string) {
    this.apiExpiredUrl = value;
  }

  private getConnectedUser() {
    const result = new ConnectedUser();

    if (this.connection) {
      const entries = this.connection.split(';');
      entries.forEach((entry) => {
        const pos = entry.indexOf(':');
        const key = entry.substr(0, pos);
        const value = entry.substr(pos + 1);
        if (key && value) {
          switch (key.trim()) {
            case 'baseaddress':
              result.baseAddress = value.trim();
              break;
            case 'domain':
              result.domain = value.trim();
              break;
            case 'username':
              result.name = value.trim();
              break;
            case 'password':
              result.password = value.trim();
              break;
            default:
              break;
          }
        }
      });
    }

    return result;
  }

  private acquireToken() {
    if (this.connection) {
      const headers: HttpHeaders = this.token
        ? new HttpHeaders()
            .append('token', this.token)
            .append('conn', this.connection)
        : new HttpHeaders().append('conn', this.connection);
      const urlGetToken = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'basic/resources',
        'init',
        this.serviceType
      );
      return this.http.get(urlGetToken, { headers }).pipe(
        tap((token: string) => {
          this.token = token;
        })
      );
    } else {
      const headers: HttpHeaders = this.token
        ? new HttpHeaders().append('token', this.token)
        : undefined;

      const urlGetToken = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'win/resources',
        'init',
        this.serviceType
      );
      return this.http
        .get(
          urlGetToken,
          headers
            ? { headers, withCredentials: true }
            : { withCredentials: true }
        )
        .pipe(
          tap((token: string) => {
            this.token = token;
          })
        );
    }
  }

  private convertToBasicResource(resource: any) {
    const result: any = {};
    result.DisplayName = resource.displayname ? resource.displayname : '';
    result.ObjectID = resource.objectid ? resource.objectid : '';
    result.ObjectType = resource.objecttype ? resource.objecttype : '';
    return result;
  }

  private prepareAttributesToLoad(attributes: Array<string>): AttributesToLoad {
    const result = new AttributesToLoad();

    if (attributes && attributes.length > 0) {
      attributes.forEach((a: string) => {
        if (a.indexOf(':') > 0) {
          const def = a.split(':');
          if (
            result.attributes.findIndex(
              (e) => e.toLowerCase() === def[0].toLowerCase()
            ) < 0
          ) {
            result.attributes.push(def[0]);
          }
          const pos = result.resolves.findIndex(
            (e) =>
              e.reference && e.reference.toLowerCase() === def[0].toLowerCase()
          );
          if (pos < 0) {
            result.resolves.push({ reference: def[0], attributes: [def[1]] });
          } else {
            if (
              result.resolves[pos].attributes.findIndex(
                (e) => e.toLowerCase() === def[1].toLowerCase()
              ) < 0
            ) {
              result.resolves[pos].attributes.push(def[1]);
            }
          }
        } else {
          if (
            result.attributes.findIndex(
              (e) => e.toLowerCase() === a.toLowerCase()
            ) < 0
          ) {
            result.attributes.push(a);
          }
          if (
            this.attributeSchema[a] &&
            this.attributeSchema[a].dataType === 'Reference'
          ) {
            const pos = result.resolves.findIndex(
              (e) =>
                e.reference && e.reference.toLowerCase() === a.toLowerCase()
            );
            if (pos < 0) {
              result.resolves.push({
                reference: a,
                attributes: ['DisplayName'],
              });
            } else {
              if (
                result.resolves[pos].attributes.findIndex(
                  (e) => e.toLowerCase() === 'displayname'
                ) < 0
              ) {
                result.resolves[pos].attributes.push('DisplayName');
              }
            }
          }
        }
      });
    }

    if (result.attributes.length === 0) {
      result.attributes = null;
    }
    if (result.resolves.length === 0) {
      result.resolves = [
        {
          reference: '*',
          attributes: ['DisplayName'],
        },
      ];
    }

    return result;
  }

  public checkCurrentViewSet() {
    if (this.adminUiSets && this.primaryUiSet) {
      const found = this.adminUiSets.find(
        (s) =>
          s.ObjectID.toLowerCase() === this.primaryUiSet.ObjectID.toLowerCase()
      );
      this.isAdminUiSet = found ? true : false;
    } else {
      this.isAdminUiSet = false;
    }

    if (this.loginUser) {
      const loginId = this.utils.ExtraValue(this.loginUser, 'ObjectID');
      if (
        loginId === '009c3cd4-7ccf-4f19-99ff-5c27afdcfb7d' ||
        loginId === '7fb2b853-24f0-4498-9534-4e10589723c4'
      ) {
        this.isAdminUiSet = true;
      }
    }
  }

  public showInfo() {
    return `Dataservice Version: ${
      this.dataServiceVersion
    }<br />Authentication Mode: ${this.authNMode}<br />Browser Language: ${
      this.browserLanguage
    }<br />Secret: ${this.secret}<br />Access Token: ${
      this.token
    }<br />Login User: ${this.user ? this.user.DisplayName : ''}`;
  }

  public clear() {
    this.authNMode = undefined;
    this.encryptionKey = '';
    this.secret = '';
    this.loginUserAttributes = [];
    this.version = '';
    this.baseUrl = '';
    this.language = '';
    this.connUser = undefined;
    this.user = undefined;
    this.loaded = false;
    this.connection = '';
    this.token = '';
  }

  public buildConnectionString(
    userName: string,
    password: string,
    domain?: string,
    baseAddress?: string
  ) {
    if (!domain) {
      domain = this.config.getConfig('domain');
    }
    if (!baseAddress) {
      baseAddress = '//localhost:5725';
    }
    const encryptedpwd = this.utils.Encrypt(
      password,
      this.config.getConfig('encryptionKey')
    );
    return `baseaddress:${baseAddress};domain:${domain};username:${userName};password:${encryptedpwd}`;
  }

  public setService(info: AuthUser) {
    this.authNMode = info.AuthenticationMode;
    this.swap.authenticationMode = this.authNMode;
    this.token = info.AccessToken;
    this.connection = info.AccessConnection;
  }

  public load(conn?: string) {
    if (this.authNMode === AuthMode.azure) {
      this.baseUrl = this.config.getConfig(
        'nextGenServiceUrl',
        '//localhost:6867/api/'
      );
      this.loginUserAttributes = this.config.getConfig('loginUserAttributes', [
        'DisplayName',
      ]);

      this.version = 'IDA2';
      this.language = 'en';

      this.loaded = true;

      return this.http.get(`${this.baseUrl}schema`).pipe(
        tap((result: any) => {
          this.objectSchema = result;
          for (const key in this.objectSchema) {
            if (this.objectSchema[key].bindings) {
              for (const attr in this.objectSchema[key].bindings) {
                if (!this.attrSchema[attr]) {
                  this.attrSchema[attr] = this.objectSchema[key].bindings[attr];
                }
              }
            }
          }
        }),
        switchMap(() => {
          const urlGetVersion = this.utils.buildDataServiceUrl(
            this.baseUrl,
            'public',
            'version'
          );
          return this.http.get(urlGetVersion);
        }),
        tap((versionResult: string) => {
          this.version = versionResult;
        }),
        finalize(() => {
          this.loaded = true;
        })
      );
    } else {
      this.baseUrl = this.config.getConfig(
        'dataServiceUrl',
        '//localhost:6867/api/'
      );
      this.loginUserAttributes = this.config.getConfig('loginUserAttributes', [
        'DisplayName',
      ]);

      if (conn) {
        this.connection = conn;
        this.authNMode = AuthMode.basic;
        this.connUser = this.getConnectedUser();
      } else {
        this.connection = undefined;
        this.authNMode = AuthMode.windows;
      }

      const urlGetVersion = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'general',
        'version',
        this.serviceType
      );
      // get version
      return this.http.get(urlGetVersion).pipe(
        tap((ver: string) => {
          this.version = ver;
        }),
        // get encryption key
        switchMap(() => {
          const urlGetEncryptionKey = this.utils.buildDataServiceUrl(
            this.baseUrl,
            'general',
            'encryptionKey',
            this.serviceType
          );
          return this.http.get(urlGetEncryptionKey).pipe(
            tap((key: string) => {
              if (!key) {
                return throwError(new Error('could not get encryption key'));
              }
              this.encryptionKey = this.utils.Decrypt(key, '');
              this.secret = this.utils.Encrypt(key, this.encryptionKey);
            })
          );
        }),
        // get language
        switchMap(() => {
          const urlGetLanguage = this.utils.buildDataServiceUrl(
            this.baseUrl,
            'general',
            'language',
            this.serviceType
          );
          return this.http.get(urlGetLanguage).pipe(
            tap((lang: string) => {
              if (!lang) {
                return throwError(new Error('could not get browser language'));
              }
              this.language = lang;
            })
          );
        }),
        // acquire token
        switchMap(() => {
          return this.acquireToken().pipe(
            tap(() => {
              this.loaded = true;
            })
          );
        })
      );
    }
  }

  /**
   * get the available standard, primary and admin ui settings
   * requires ocgAdminViewSetRefs and ocgPrimaryViewSetRef attribute on User object
   * and ocgConfigurationXML on Set object
   */
  public getUserConfig(): Observable<any> {
    if (this.authNMode === AuthMode.azure) {
      const tagRightSet = 'rightset';
      const tagUISettings = 'uisettings';

      const urlSearch = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'resources/search'
      );
      const urlXPathTemplates = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'user/xpathtemplates'
      );

      // get right sets
      const paramsGetRightSets: HttpParams = new HttpParams({
        fromObject: {
          tags: tagRightSet,
        },
      });

      return this.http
        .get<Resource[]>(urlXPathTemplates, { params: paramsGetRightSets })
        .pipe(
          tap((resultRightSets: Resource[]) => {
            this.rights = resultRightSets.map((item) => item.displayname);
          }),
          // get view sets
          switchMap(() => {
            const paramsGetViewSets: HttpParams = new HttpParams({
              fromObject: {
                tags: tagUISettings,
              },
            });
            return this.http.get<Resource[]>(urlXPathTemplates, {
              params: paramsGetViewSets,
            });
          }),
          tap((resultViewSets: Resource[]) => {
            if (resultViewSets) {
              this.uiSets = resultViewSets.map((item) =>
                this.convertToBasicResource(item)
              );
            }
          }),
          // get admin view sets
          switchMap(() => {
            const paramsGetAdminViewSets: HttpParams = new HttpParams({
              fromObject: {
                xPathQuery: `/xpathtemplate[tags='${tagUISettings}' and objectid=/person[objectid='${this.loginUser.objectid}']/${this.utils.attAdminViewSets}]`,
                attributes: 'DisplayName',
              },
            });
            return this.http.get<ResourceSet>(urlSearch, {
              params: paramsGetAdminViewSets,
            });
          }),
          tap((resultAdminViewSets: ResourceSet) => {
            if (resultAdminViewSets && resultAdminViewSets.results) {
              this.adminUiSets = resultAdminViewSets.results.map((item) => {
                // if (
                //   this.uiSets.findIndex(
                //     (u) =>
                //       u.ObjectID &&
                //       item.objectid &&
                //       u.ObjectID.toLowerCase() === item.objectid.toLowerCase()
                //   ) >= 0
                // ) {
                //   return this.convertToBasicResource(item);
                // }

                return this.convertToBasicResource(item);
              });
            }
          }),
          // get primary view set
          switchMap(() => {
            const paramsGetPrimaryViewSet: HttpParams = new HttpParams({
              fromObject: {
                xPathQuery: `/xpathtemplate[tags='${tagUISettings}' and objectid=/person[objectid='${this.loginUser.objectid}']/${this.utils.attPrimaryViewSets}]`,
                attributes: `DisplayName,${this.utils.attConfiguration},${this.utils.attObjectStatus}`,
              },
            });
            return this.http.get<ResourceSet>(urlSearch, {
              params: paramsGetPrimaryViewSet,
            });
          }),
          // get standard view set if no primary is found
          switchMap((resultPrimarySet: ResourceSet) => {
            let needStandardSet = true;
            if (
              resultPrimarySet &&
              resultPrimarySet.results &&
              resultPrimarySet.results.length > 0 &&
              resultPrimarySet.results[0][
                this.utils.attConfiguration.toLowerCase()
              ]
            ) {
              if (
                this.uiSets.findIndex(
                  (u) =>
                    u.ObjectID &&
                    resultPrimarySet.results[0].objectid &&
                    u.ObjectID.toLowerCase() ===
                      resultPrimarySet.results[0].objectid.toLowerCase()
                ) >= 0
              ) {
                needStandardSet = false;
              }
            }

            if (needStandardSet) {
              const scopeName = this.config.getConfig(
                'standardUISet',
                undefined
              );
              const paramsGetStandardViewSet: HttpParams = new HttpParams({
                fromObject: {
                  xPathQuery: scopeName
                    ? `/xpathtemplate[tags='${tagUISettings}' and ${this.utils.attObjectType}='uibase' and ${this.utils.attObjectScope}='${scopeName}']`
                    : `/xpathtemplate[tags='${tagUISettings}' and ${this.utils.attObjectType}='uibase' and not(starts-with(${this.utils.attObjectScope}, '%'))]`,
                  attributes: `DisplayName,${this.utils.attConfiguration},${this.utils.attObjectStatus}`,
                },
              });
              return this.http.get<ResourceSet>(urlSearch, {
                params: paramsGetStandardViewSet,
              });
            } else {
              this.primaryUiSet = this.convertToBasicResource(
                resultPrimarySet.results[0]
              );
              let uisettings: string =
                resultPrimarySet.results[0][
                  this.utils.attConfiguration.toLowerCase()
                ];
              if (uisettings && uisettings.startsWith('-ZipB64-')) {
                uisettings = this.utils.unzipString(uisettings.substring(8));
              }
              this.primaryUiSetting = uisettings;
              this.primaryUiString = uisettings;
              this.primaryUiTimestamp =
                resultPrimarySet.results[0][
                  this.utils.attObjectStatus.toLowerCase()
                ];

              const paramsGetStandardViewSet: HttpParams = new HttpParams({
                fromObject: {
                  xPathQuery: `/xpathtemplate[tags='${tagUISettings}' and ${this.utils.attObjectType}='uibase']`,
                  attributes: 'DisplayName',
                },
              });
              return this.http.get<ResourceSet>(urlSearch, {
                params: paramsGetStandardViewSet,
              });
            }
          }),
          tap((resultStandardSet: ResourceSet) => {
            if (
              resultStandardSet &&
              resultStandardSet.results &&
              resultStandardSet.results.length > 0
            ) {
              this.standardUiSet = this.convertToBasicResource(
                resultStandardSet.results[0]
              );
              if (
                resultStandardSet.results[0][
                  this.utils.attConfiguration.toLowerCase()
                ]
              ) {
                let uisettings: string =
                  resultStandardSet.results[0][
                    this.utils.attConfiguration.toLowerCase()
                  ];
                if (uisettings && uisettings.startsWith('-ZipB64-')) {
                  uisettings = this.utils.unzipString(uisettings.substring(8));
                }
                this.standardUiSetting = uisettings;
                this.standardUiString = uisettings;

                this.primaryUiSet = this.convertToBasicResource(
                  resultStandardSet.results[0]
                );
                this.primaryUiSetting = uisettings;
                this.primaryUiString = uisettings;
                this.primaryUiTimestamp =
                  resultStandardSet.results[0][
                    this.utils.attObjectStatus.toLowerCase()
                  ];
              }
            }

            this.checkCurrentViewSet();
            this.configured = true;
          })
        );
    } else {
      // get right sets
      const queryGetRightSets = `/Set[ComputedMember='${this.loginUser.ObjectID}']`;
      const paramsGetRightSets: HttpParams = new HttpParams({
        fromObject: {
          query: queryGetRightSets,
          attributes: 'DisplayName',
        },
      });

      let urlSchema: string;
      let urlSearchResource: string;

      let headers: HttpHeaders;
      let request: Observable<ResourceSet>;

      if (this.connection) {
        urlSchema = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources',
          'schema/types',
          this.serviceType
        );
        urlSearchResource = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources',
          'search',
          this.serviceType
        );
        headers = new HttpHeaders().append('token', this.token);
        request = this.http.get<ResourceSet>(urlSearchResource, {
          headers,
          params: paramsGetRightSets,
        });
      } else {
        urlSchema = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources',
          'schema/types',
          this.serviceType
        );
        urlSearchResource = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources',
          'search',
          this.serviceType
        );
        headers = new HttpHeaders().append('token', this.token);
        request = this.http.get<ResourceSet>(urlSearchResource, {
          headers,
          params: paramsGetRightSets,
          withCredentials: true,
        });
      }

      // get right sets
      return request.pipe(
        tap((data: ResourceSet) => {
          this.rights = data.results.map((item) => item.DisplayName);
        }),
        switchMap(() => {
          return this.http.get<any>(urlSchema, {
            headers,
            withCredentials: true,
          });
        }),
        tap((result: any) => {
          this.objectSchema = result;
        }),
        // get view sets
        switchMap(() => {
          const queryGetViewSets = `/Set[${this.utils.attObjectType}='ui' and ComputedMember='${this.loginUser.ObjectID}']`;
          const paramsGetViewSets: HttpParams = new HttpParams({
            fromObject: {
              query: queryGetViewSets,
              attributes: 'DisplayName',
            },
          });
          return this.http
            .get<ResourceSet>(urlSearchResource, {
              headers,
              params: paramsGetViewSets,
              withCredentials: true,
            })
            .pipe(
              tap((data: ResourceSet) => {
                this.uiSets = data.results.map(
                  (item) => new BasicResource(item)
                );
              })
            );
        }),
        // get admin view sets
        switchMap(() => {
          const queryGetAdminViewSets = `/Set[starts-with(${this.utils.attObjectType},'ui') and ObjectID=/Person[ObjectID='${this.loginUser.ObjectID}']/${this.utils.attAdminViewSets}]`;
          const paramsGetAdminViewSets: HttpParams = new HttpParams({
            fromObject: {
              query: queryGetAdminViewSets,
              attributes: 'DisplayName',
            },
          });
          return this.http
            .get<ResourceSet>(urlSearchResource, {
              headers,
              params: paramsGetAdminViewSets,
              withCredentials: true,
            })
            .pipe(
              tap((data: ResourceSet) => {
                this.adminUiSets = data.results.map(
                  (item) => new BasicResource(item)
                );
              })
            );
        }),
        // get primary view set
        switchMap(() => {
          const queryGetPrimaryViewSet = `/Set[${this.utils.attObjectType}='ui' and ObjectID=/Person[ObjectID='${this.loginUser.ObjectID}']/${this.utils.attPrimaryViewSets}]`;
          const paramsGetPrimaryViewSet: HttpParams = new HttpParams({
            fromObject: {
              query: queryGetPrimaryViewSet,
              attributes: `DisplayName, ${this.utils.attConfiguration}, ${this.utils.attObjectStatus}`,
            },
          });
          return this.http.get<ResourceSet>(urlSearchResource, {
            headers,
            params: paramsGetPrimaryViewSet,
            withCredentials: true,
          });
        }),
        // get standard view set if no primary view set
        switchMap((resultPrimarySet: ResourceSet) => {
          let needStandardSet = true;
          if (
            resultPrimarySet &&
            resultPrimarySet.results &&
            resultPrimarySet.results.length > 0 &&
            resultPrimarySet.results[0][this.utils.attConfiguration]
          ) {
            if (
              this.uiSets.findIndex(
                (u) =>
                  u.ObjectID &&
                  resultPrimarySet.results[0].ObjectID &&
                  u.ObjectID.toLowerCase() ===
                    resultPrimarySet.results[0].ObjectID.toLowerCase()
              ) >= 0
            ) {
              needStandardSet = false;
            }
          }

          if (needStandardSet) {
            const scopeName = this.config.getConfig('standardUISet', undefined);
            const queryGetStandardViewSet = scopeName
              ? `/Set[${this.utils.attObjectType}='uibase' and ${this.utils.attObjectScope}='${scopeName}']`
              : `/Set[${this.utils.attObjectType}='uibase' and not(starts-with(${this.utils.attObjectScope}, '%'))]`;
            const paramsGetStandardViewSet: HttpParams = new HttpParams({
              fromObject: {
                query: queryGetStandardViewSet,
                attributes: `DisplayName, ${this.utils.attConfiguration}, ${this.utils.attObjectStatus}`,
              },
            });
            return this.http.get<ResourceSet>(urlSearchResource, {
              headers,
              params: paramsGetStandardViewSet,
              withCredentials: true,
            });
          } else {
            this.primaryUiSet = new BasicResource(resultPrimarySet.results[0]);
            this.primaryUiSetting =
              resultPrimarySet.results[0][this.utils.attConfiguration];
            this.primaryUiString =
              resultPrimarySet.results[0][this.utils.attConfiguration];
            this.primaryUiTimestamp =
              resultPrimarySet.results[0][this.utils.attObjectStatus];

            const queryGetStandardViewSet = `/Set[${this.utils.attObjectType}='uibase']`;
            const paramsGetStandardViewSet: HttpParams = new HttpParams({
              fromObject: {
                query: queryGetStandardViewSet,
                attributes: `DisplayName`,
              },
            });
            return this.http.get<ResourceSet>(urlSearchResource, {
              headers,
              params: paramsGetStandardViewSet,
              withCredentials: true,
            });
          }
        }),
        tap((resultStandardSet: ResourceSet) => {
          if (
            resultStandardSet &&
            resultStandardSet.results &&
            resultStandardSet.results.length > 0
          ) {
            this.standardUiSet = new BasicResource(
              resultStandardSet.results[0]
            );

            if (resultStandardSet.results[0][this.utils.attConfiguration]) {
              this.standardUiSetting =
                resultStandardSet.results[0][this.utils.attConfiguration];
              this.standardUiString =
                resultStandardSet.results[0][this.utils.attConfiguration];

              this.primaryUiSet = this.standardUiSet;
              this.primaryUiSetting =
                resultStandardSet.results[0][this.utils.attConfiguration];
              this.primaryUiString =
                resultStandardSet.results[0][this.utils.attConfiguration];
              this.primaryUiTimestamp =
                resultStandardSet.results[0][this.utils.attObjectStatus];
            }

            this.checkCurrentViewSet();
            this.configured = true;
          }
        })
      );
    }
  }

  /**
   * get current login user with attributes defined in config.json
   * requires ocgConfigurationXML attribute on User object
   * @param isAuth if set to true, only minimal attributes will be loaded to authenticate the user
   */
  public getCurrentUser(isAuth = false): Observable<Resource> {
    if (this.authNMode === AuthMode.azure) {
      const urlGetCurrentUser = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'user/person'
      );
      if (!this.loginUserAttributes.includes(this.utils.attConfiguration)) {
        this.loginUserAttributes.push(this.utils.attConfiguration);
      }
      const params: HttpParams = new HttpParams({
        fromObject: {
          attributes: isAuth
            ? 'DisplayName'
            : this.loginUserAttributes.join(','),
        },
      });
      return this.http.get(urlGetCurrentUser, { params }).pipe(
        tap((result: Resource) => {
          if (result) {
            this.user = result;
            const cuSetting = this.utils.ExtraValue(
              this.user,
              this.utils.attConfiguration
            );
            if (cuSetting) {
              this.customUiSetting = cuSetting;
            }
            const cuString = this.utils.ExtraValue(
              this.user,
              this.utils.attConfiguration
            );
            if (cuString) {
              this.customUiString = cuString;
            }
          }
        })
      );
    } else {
      if (this.connection) {
        // using basic authentication
        const urlGetPortalUser = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources',
          'user',
          this.serviceType
        );
        if (!this.connectedUser.name) {
          return throwError(new Error('invalid connection'));
        }
        if (!this.loginUserAttributes.includes(this.utils.attConfiguration)) {
          this.loginUserAttributes.push(this.utils.attConfiguration);
        }
        const params: HttpParams = new HttpParams({
          fromObject: {
            accountName: this.connectedUser.name,
            attributes: isAuth
              ? 'DisplayName'
              : this.loginUserAttributes.join(','),
          },
        });
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        return this.http.get(urlGetPortalUser, { headers, params }).pipe(
          tap((user: Resource) => {
            this.user = user;
            if (user[this.utils.attConfiguration]) {
              this.customUiSetting = user[this.utils.attConfiguration];
              this.customUiString = user[this.utils.attConfiguration];
            }
          })
        );
      } else {
        // using windows authentication
        const urlGetPortalUser = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources',
          'user',
          this.serviceType
        );
        if (!this.loginUserAttributes.includes(this.utils.attConfiguration)) {
          this.loginUserAttributes.push(this.utils.attConfiguration);
        }
        const params: HttpParams = new HttpParams({
          fromObject: {
            attributes: isAuth
              ? 'DisplayName'
              : this.loginUserAttributes.join(','),
          },
        });
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        return this.http
          .get(urlGetPortalUser, { headers, params, withCredentials: true })
          .pipe(
            tap((user: Resource) => {
              this.user = user;
              if (user[this.utils.attConfiguration]) {
                this.customUiSetting = user[this.utils.attConfiguration];
                this.customUiString = user[this.utils.attConfiguration];
              }
            })
          );
      }
    }
  }

  public getNextGenWorkflowByID(id: string): Observable<Resource> {
    if (!id) {
      return throwError('id is missing');
    }

    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      `ui/workflow/${id}`
    );

    return this.http.get<Resource>(url);
  }

  public updateNextGenWorkflow(resource: Resource) {
    if (!resource) {
      return throwError('resource is missing');
    }

    const resourceToUpdate = this.utils.DeepCopy(resource);
    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      'ui/workflow',
      resource.objectid
    );

    return this.http.patch(url, resourceToUpdate);
  }

  public importResourceFromFile(
    file: File,
    objectTypes: string,
    attributeBlacklist: string,
    objectReferencesToInclude: string,
    simulationMode: boolean
  ) {
    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      'import/non-schema'
    );
    const headers: HttpHeaders = new HttpHeaders();
    const params: HttpParams = new HttpParams({
      fromObject: {
        objectTypes,
        overwriteMode: 'SkipExistingResources',
        nonExistingReferenceMode: 'RemoveNonExistingReference',
        attributeBlacklist,
        objectReferencesToInclude,
        returnImportedResources: 'false',
        simulationMode: String(simulationMode),
      },
    });
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<any>(url, formData, { params, headers });
  }

  /**
   *
   * @param method The http method, get | put | delete | patch | post
   * @param path The API path
   * @param param The http parameters
   * @param body The http body
   * @param header The http header
   * @returns object of any
   */
  public callApi(
    method: string,
    path: string,
    param?: any,
    body?: any,
    header?: any
  ): Observable<any> {
    if (!method) {
      return throwError('method is missing');
    }
    if (!path) {
      return throwError('path is missing');
    }

    let request: Observable<any>;
    const url = this.utils.buildDataServiceUrl(this.baseUrl, path);

    const params = param
      ? new HttpParams({
          fromObject: param,
        })
      : null;

    let headers: HttpHeaders = new HttpHeaders();
    if (header) {
      Object.keys(header).forEach((k) => {
        headers = headers.append(k, header[k]);
      });
    }
    if (
      this.authNMode === AuthMode.basic ||
      this.authNMode === AuthMode.windows
    ) {
      headers = headers.append('token', this.token);
    }

    switch (method.toLowerCase()) {
      case 'get':
        request = this.http.get<any>(url, {
          headers,
          params,
          withCredentials: this.authNMode === AuthMode.windows ? true : false,
        });
        break;
      case 'put':
        request = this.http.put<any>(url, body, {
          headers,
          params,
          withCredentials: this.authNMode === AuthMode.windows ? true : false,
        });
        break;
      case 'delete':
        request = this.http.delete<any>(url, { headers });
        break;
      case 'patch':
        request = this.http.patch<any>(url, body, {
          headers,
          params,
          withCredentials: this.authNMode === AuthMode.windows ? true : false,
        });
        break;
      case 'post':
        request = this.http.post<any>(url, body, {
          headers,
          params,
          withCredentials: this.authNMode === AuthMode.windows ? true : false,
        });
        break;
      default:
        break;
    }

    return request;
  }

  public getResourceByID(
    id: string,
    attributes: string[] = [],
    format = 'simple',
    culture = 'en-US',
    resolveRef = 'false',
    adminMode = false,
    excludeMvr = 'false',
    simulationID = '',
    simulationActor = '',
    skipTimestamp = false
  ): Observable<Resource> {
    if (!id) {
      return throwError('id is missing');
    }

    if (!skipTimestamp) {
      this.apiTimestamp = moment();
    }

    let request: Observable<Resource>;

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        `resources/${id}`
      );
      const body = {
        attributes: attributes && attributes.length > 0 ? attributes : null,
        queryFormat:
          format && format.toLowerCase() === 'full' ? 'Verbose' : 'IncludeNull',
        resolveReferences:
          resolveRef && resolveRef.toLowerCase() === 'true'
            ? [
                {
                  reference: '*',
                  attributes: ['DisplayName'],
                },
              ]
            : null,
        includeEventArchive: 'true',
      };

      let params: HttpParams;
      if (simulationID) {
        params = new HttpParams({
          fromObject: {
            SimulationSessionId: simulationID,
            SimulationActorId: simulationActor ? simulationActor : '',
          },
        });
      }

      request = params
        ? this.http.post<Resource>(url, body, { params })
        : this.http.post<Resource>(url, body);

      return request;
    } else {
      const params: HttpParams = new HttpParams({
        fromObject: {
          attributes: attributes.join(','),
          culture,
          resolveRef,
          format,
          excludeMvr,
        },
      });

      // if (adminMode === true) {
      //   const url = this.utils.buildDataServiceUrl(
      //     this.baseUrl,
      //     'admin',
      //     'resources',
      //     this.serviceType,
      //     [id]
      //   );
      //   const headers: HttpHeaders = new HttpHeaders().append(
      //     'secret',
      //     this.secret
      //   );
      //   request = this.http.get<Resource>(url, { headers, params });
      // } else
      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic',
          'resources',
          this.serviceType,
          [id]
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<Resource>(url, { headers, params });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win',
          'resources',
          this.serviceType,
          [id]
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<Resource>(url, {
          headers,
          params,
          withCredentials: true,
        });
      }

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.getResourceByID(
                  id,
                  attributes,
                  format,
                  culture,
                  resolveRef,
                  adminMode,
                  excludeMvr
                );
              })
            );
          } else {
            return throwError(err);
          }
        })
      );
    }
  }

  public getResourceSchema(
    typeName: string,
    culture = 'en-US'
  ): Observable<Resource> {
    if (!typeName) {
      return throwError('type name is missing');
    }

    let request: Observable<Resource>;

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'schema',
        typeName
      );
      request = this.http.get<Resource>(url);

      return request;
    } else {
      const params: HttpParams = new HttpParams({
        fromObject: {
          culture,
        },
      });

      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'schema',
        typeName,
        this.serviceType
      );
      const headers: HttpHeaders = new HttpHeaders().append(
        'token',
        this.token
      );
      request = this.http.get<Resource>(url, { headers, params });

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.getResourceSchema(typeName, culture);
              })
            );
          } else {
            return throwError(err);
          }
        })
      );
    }
  }

  public getResourceByQuery(
    query: string,
    attributes: string[] = [],
    pageSize = 0,
    index = 0,
    resolveRef = false,
    orderBy: string[] = [],
    adminMode = false,
    includeCount = true,
    simulationID = '',
    simulationActor = '',
    skipTimestamp = false
  ): Observable<ResourceSet> {
    if (!query) {
      return throwError('query is missing');
    }

    if (!skipTimestamp) {
      this.apiTimestamp = moment();
    }

    let request: Observable<ResourceSet>;

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'resources/search'
      );

      // prevent plus sign to be encoded to %20 (space sign)
      // query = query.replace(/\+/gi, '&#43;');

      const params = new HttpParams(
        simulationID
          ? {
              fromObject: {
                // xPathQuery: this.lookup(query),
                SimulationSessionId: simulationID,
                SimulationActorId: simulationActor,
              },
            }
          : {
              fromObject: {
                // xPathQuery: this.lookup(query),
              },
            }
      );

      const sort = orderBy.map((item) => {
        const elements = item.split(':');
        if (elements.length === 2) {
          return {
            attribute: elements[0],
            order: elements[1],
          };
        }
      });
      let ob = sort && sort.length > 0 ? sort[0] : null;
      if (ob) {
        if (ob.order && ob.order.toLowerCase() === 'asc') {
          ob.order = 'ascending';
        }
        if (ob.order && ob.order.toLowerCase() === 'desc') {
          ob.order = 'descending';
        }
      } else {
        if (
          attributes &&
          attributes.findIndex((a) => a.toLowerCase() === 'displayname') >= 0
        ) {
          ob = {
            attribute: 'displayname',
            order: 'ascending',
          };
        }
      }

      const attributesDef = this.prepareAttributesToLoad(attributes);

      const body =
        pageSize === 0
          ? {
              xpath: query,
              attributes: attributesDef.attributes,
              queryFormat: 'IncludeNull',
              pageSize: this.config.getConfig('pageSize', 50),
              orderBy: ob,
              resolveReferences: null,
              includeCount: includeCount ? 'FastOnly' : 'None',
              includeEventArchive: true,
            }
          : {
              xpath: query,
              attributes: attributesDef.attributes,
              queryFormat: 'IncludeNull',
              pageSize,
              orderBy: ob,
              resolveReferences: null,
              includeCount: includeCount ? 'FastOnly' : 'None',
              includeEventArchive: true,
            };
      if (resolveRef) {
        body.resolveReferences = attributesDef.resolves;
      }

      return this.http.post<ResourceSet>(url, body, { params });

      // const url = this.utils.buildDataServiceUrl(this.baseUrl, 'resources');
      // const params: HttpParams = new HttpParams({
      //   fromObject: {
      //     xPathQuery: query,
      //     attributes: attributes.join(','),
      //     pageSize: String(pageSize),
      //     skip: String(index)
      //   }
      // });
      // request = this.http.get<ResourceSet>(url, { params });

      // return request;
    } else {
      const params: HttpParams = new HttpParams({
        fromObject: {
          query: this.lookup(query),
          attributes: attributes.join(','),
          pageSize: String(pageSize),
          index: String(index),
          resolveRef: String(resolveRef),
          orderBy: orderBy.join(','),
        },
      });

      // if (adminMode === true) {
      //   const url = this.utils.buildDataServiceUrl(
      //     this.baseUrl,
      //     'admin/resources',
      //     'search',
      //     this.serviceType
      //   );
      //   const headers: HttpHeaders = new HttpHeaders().append(
      //     'secret',
      //     this.secret
      //   );
      //   request = this.http.get<ResourceSet>(url, { headers, params });
      // } else

      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources',
          'search',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<ResourceSet>(url, { headers, params });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources',
          'search',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<ResourceSet>(url, {
          headers,
          params,
          withCredentials: true,
        });
      }

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.getResourceByQuery(
                  query,
                  attributes,
                  pageSize,
                  index,
                  resolveRef,
                  orderBy,
                  adminMode
                );
              })
            );
          } else {
            return throwError(err);
          }
        })
      );
    }
  }

  public deleteResource(
    id: string,
    adminMode = false,
    simulationID = '',
    simulationActor = ''
  ): Observable<void | HttpResponse<void>> {
    if (!id) {
      return throwError('id is missing');
    }

    let request: Observable<HttpResponse<void>>;

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(this.baseUrl, 'resources', id);

      const params = simulationID
        ? new HttpParams({
            fromObject: {
              SimulationSessionId: simulationID,
              SimulationActorId: simulationActor ? simulationActor : '',
            },
          })
        : null;

      return params
        ? this.http.delete<void>(url, { params, observe: 'response' }).pipe(
            tap(() => {
              this.swap.broadcast({ name: 'delete-resource', parameter: id });
            })
          )
        : this.http.delete<void>(url, { observe: 'response' }).pipe(
            tap(() => {
              this.swap.broadcast({ name: 'delete-resource', parameter: id });
            })
          );
    } else {
      // if (adminMode === true) {
      //   const url = this.utils.buildDataServiceUrl(
      //     this.baseUrl,
      //     'admin/resources',
      //     id,
      //     this.serviceType
      //   );
      //   const headers: HttpHeaders = new HttpHeaders().append(
      //     'secret',
      //     this.secret
      //   );
      //   request = this.http.delete<void>(url, { headers, observe: 'response' });
      // } else
      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources',
          id,
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.delete<void>(url, { headers, observe: 'response' });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources',
          id,
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.delete<void>(url, {
          headers,
          withCredentials: true,
          observe: 'response',
        });
      }

      return request.pipe(
        tap(() => {
          this.swap.broadcast({ name: 'delete-resource', parameter: id });
        }),
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.deleteResource(id, adminMode);
              })
            );
          } else {
            if (err.statusText === 'OK') {
              return of(null);
            } else {
              return throwError(err);
            }
          }
        })
      );
    }
  }

  public deleteResourceByQuery(
    query: string,
    simulationID = '',
    simulationActor = ''
  ): Observable<void | HttpResponse<void>> {
    if (!query) {
      return throwError('query is missing');
    }

    let request: Observable<HttpResponse<void>>;

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'resources',
        'xpath'
      );

      // prevent plus sign to be encoded to %20 (space sign)
      query = query.replace(/\+/gi, '&#43;');

      const params = simulationID
        ? new HttpParams({
            fromObject: {
              xpath: query,
              SimulationSessionId: simulationID,
              SimulationActorId: simulationActor ? simulationActor : '',
            },
          })
        : new HttpParams({
            fromObject: {
              xpath: query,
            },
          });

      return this.http.delete<void>(url, { params, observe: 'response' });
    } else {
      const params: HttpParams = new HttpParams({
        fromObject: {
          query: this.lookup(query),
        },
      });

      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic',
          'resources',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.delete<void>(url, {
          headers,
          params,
          observe: 'response',
        });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win',
          'resources',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.delete<void>(url, {
          headers,
          params,
          withCredentials: true,
          observe: 'response',
        });
      }

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.deleteResourceByQuery(query);
              })
            );
          } else {
            if (err.statusText === 'OK') {
              return of(null);
            } else {
              return throwError(err);
            }
          }
        })
      );
    }
  }

  public createResource(
    resource: Resource,
    adminMode = false,
    simulationID = '',
    simulationActor = ''
  ): Observable<HttpResponse<any>> {
    if (!resource) {
      return throwError('resource is missing');
    }

    let request: Observable<HttpResponse<any>>;

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(this.baseUrl, 'resources');
      const params: HttpParams = new HttpParams(
        simulationID
          ? {
              fromObject: {
                Attributes: ['objectid'],
                SimulationSessionId: simulationID,
                SimulationActorId: simulationActor,
              },
            }
          : {
              fromObject: {
                Attributes: ['objectid'],
              },
            }
      );
      request = this.http.post(url, resource, {
        params,
        observe: 'response',
      });

      return request;
    } else {
      // if (adminMode === true) {
      //   const url = this.utils.buildDataServiceUrl(
      //     this.baseUrl,
      //     'admin',
      //     'resources',
      //     this.serviceType
      //   );
      //   const headers: HttpHeaders = new HttpHeaders().append(
      //     'secret',
      //     this.secret
      //   );
      //   request = this.http.post<string>(url, resource, {
      //     headers,
      //     observe: 'response'
      //   });
      // } else
      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic',
          'resources',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.post<string>(url, resource, {
          headers,
          observe: 'response',
        });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win',
          'resources',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.post<string>(url, resource, {
          headers,
          withCredentials: true,
          observe: 'response',
        });
      }

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.createResource(resource, adminMode);
              })
            );
          } else {
            if (err.statusText === 'OK') {
              return of(null);
            } else {
              return throwError(err);
            }
          }
        })
      );
    }
  }

  public updateResource(
    resource: Resource,
    adminMode = false,
    simulationID = '',
    simulationActor = ''
  ): Observable<HttpResponse<any>> {
    if (!resource) {
      return throwError('resource is missing');
    }

    if (resource.DisplayName === null) {
      delete resource.DisplayName;
    }

    let request: Observable<HttpResponse<any>>;

    if (this.authNMode === AuthMode.azure) {
      const resourceToUpdate = {
        attributeAssignments: {},
      };
      Object.keys(resource).forEach((key: string) => {
        if (
          key.toLowerCase() !== 'objectid' &&
          key.toLowerCase() !== 'objecttype'
        ) {
          if (key.toLowerCase() === '$multivalueinsertions') {
            resourceToUpdate['multivalueInsertions'] = resource[key];
          } else if (key.toLowerCase() === '$multivalueremovals') {
            resourceToUpdate['multivalueRemovals'] = resource[key];
          } else {
            resourceToUpdate.attributeAssignments[key] = resource[key];
          }
        }
      });
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        `resources/ex/${this.utils.ExtraValue(resource, 'ObjectID')}`
      );

      const params = simulationID
        ? new HttpParams({
            fromObject: {
              SimulationSessionId: simulationID,
              SimulationActorId: simulationActor ? simulationActor : '',
            },
          })
        : null;

      const headers: HttpHeaders = new HttpHeaders().append(
        'Access-Control-Expose-Headers',
        'x-idabus-event-id'
      );

      request = params
        ? this.http.patch(url, resourceToUpdate, {
            headers,
            params,
            observe: 'response',
          })
        : this.http.patch(url, resourceToUpdate, {
            responseType: 'text',
            observe: 'response',
          });
      return request;
    } else {
      // if (adminMode === true) {
      //   const url = this.utils.buildDataServiceUrl(
      //     this.baseUrl,
      //     'admin',
      //     'resources',
      //     this.serviceType
      //   );
      //   const headers: HttpHeaders = new HttpHeaders().append(
      //     'secret',
      //     this.secret
      //   );
      //   request = this.http.patch<string>(url, resource, {
      //     headers,
      //     observe: 'response'
      //   });
      // } else
      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic',
          'resources',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.patch<string>(url, resource, {
          headers,
          observe: 'response',
        });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win',
          'resources',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.patch<string>(url, resource, {
          headers,
          withCredentials: true,
          observe: 'response',
        });
      }

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.updateResource(resource, adminMode);
              })
            );
          } else {
            if (err.statusText === 'OK') {
              return of(null);
            } else {
              return throwError(err);
            }
          }
        })
      );
    }
  }

  public getResourceCount(
    query: string,
    simulationID = '',
    simulationActor = '',
    skipTimestamp = false
  ): Observable<number> {
    if (!query) {
      return throwError('query is missing');
    }

    if (!skipTimestamp) {
      this.apiTimestamp = moment();
    }

    const params: HttpParams = new HttpParams({
      fromObject: {
        query: this.lookup(query),
      },
    });
    let request: Observable<number>;

    if (this.authNMode === AuthMode.azure) {
      // fake event counter
      switch (query.toLowerCase()) {
        case '/event/request':
          return of(6856);
        case '/event/resource':
          return of(34757);
        case '/event/workflow':
          return of(40943);
        case '/event/set':
          return of(4980);
        case '/event/today/finished':
          return of(26);
        case '/event/today/failed':
          return of(5);
        case '/event/yestoday/finished':
          return of(72);
        case '/event/yestoday/failed':
          return of(14);
        case '/event/dby/finished':
          return of(21);
        case '/event/dby/failed':
          return of(6);
        case '/event/dby1/finished':
          return of(33);
        case '/event/dby1/failed':
          return of(9);
        case '/event/dby2/finished':
          return of(42);
        case '/event/dby2/failed':
          return of(11);
        default:
          break;
      }
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'resources/search'
      );
      const paramsCount: HttpParams = new HttpParams(
        simulationID
          ? {
              fromObject: {
                xPathQuery: this.lookup(query),
                attributes: 'displayname',
                pageSize: '0',
                includeCount: 'forced',
                SimulationSessionId: simulationID,
                SimulationActorId: simulationActor,
              },
            }
          : {
              fromObject: {
                xPathQuery: this.lookup(query),
                attributes: 'displayname',
                pageSize: '0',
                includeCount: 'forced',
              },
            }
      );
      return this.http.get<ResourceSet>(url, { params: paramsCount }).pipe(
        switchMap((result: ResourceSet) => {
          let dic = 0;
          if (result) {
            dic = result.totalCount;
          }
          return of(dic);
        })
      );
    } else {
      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources',
          'count',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<number>(url, { headers, params });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources',
          'count',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<number>(url, {
          headers,
          params,
          withCredentials: true,
        });
      }

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.getResourceCount(query);
              })
            );
          } else {
            return throwError(err);
          }
        })
      );
    }
  }

  public resourceExists(
    query: string,
    simulationID = '',
    simulationActor = ''
  ): Observable<boolean> {
    if (!query) {
      return throwError('query is missing');
    }
    const xpathQuery = this.lookup(query);

    let request: Observable<ResourceSet>;

    if (this.authNMode === AuthMode.azure) {
      const params: HttpParams = new HttpParams(
        simulationID
          ? {
              fromObject: {
                xPathQuery: xpathQuery,
                attributes: 'ObjectID',
                pageSize: String(1),
                SimulationSessionId: simulationID,
                SimulationActorId: simulationActor,
              },
            }
          : {
              fromObject: {
                xPathQuery: xpathQuery,
                attributes: 'ObjectID',
                pageSize: String(1),
              },
            }
      );
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'resources/search'
      );
      request = this.http.get<ResourceSet>(url, { params });
    } else {
      const params: HttpParams = new HttpParams({
        fromObject: {
          query: xpathQuery,
          attributes: 'ObjectID',
          pageSize: String(1),
          index: String(0),
        },
      });
      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources',
          'search',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<ResourceSet>(url, { headers, params });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources',
          'search',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<ResourceSet>(url, {
          headers,
          params,
          withCredentials: true,
        });
      }
    }

    return request.pipe(
      switchMap((result: ResourceSet) => {
        if (
          result &&
          (result.totalCount > 0 ||
            (result.results && result.results.length > 0))
        ) {
          return of(true);
        } else {
          return of(false);
        }
      }),
      catchError((err) => {
        if (err.status === 409) {
          return this.acquireToken().pipe(
            switchMap(() => {
              return this.resourceExists(query);
            })
          );
        } else {
          return throwError(err);
        }
      })
    );
  }

  public addResourceValue(
    id: string,
    attributeName: string,
    valuesToAdd: string[] = [],
    adminMode = false,
    simulationID = '',
    simulationActor = ''
  ) {
    if (!id) {
      return throwError('id is missing');
    }
    if (!attributeName) {
      return throwError('attribute name is missing');
    }
    if (!valuesToAdd || valuesToAdd.length === 0) {
      return throwError('values to add is missing');
    }

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        `resources/multivalued/multiple/${id}/${attributeName}`
      );

      let params: HttpParams;
      if (simulationID) {
        params = new HttpParams({
          fromObject: {
            SimulationSessionId: simulationID,
            SimulationActorId: simulationActor ? simulationActor : '',
          },
        });
      }

      return params
        ? this.http.put(url, valuesToAdd, { params, observe: 'response' })
        : this.http.put(url, valuesToAdd, { observe: 'response' });
    } else {
      const params: HttpParams = new HttpParams({
        fromObject: {
          id,
          attributeName,
          valuesToAdd: valuesToAdd.join(','),
        },
      });
      let request: Observable<HttpResponse<void>>;

      // if (adminMode === true) {
      //   const url = this.utils.buildDataServiceUrl(
      //     this.baseUrl,
      //     'admin/resources',
      //     'values/add',
      //     this.serviceType
      //   );
      //   const headers: HttpHeaders = new HttpHeaders().append(
      //     'secret',
      //     this.secret
      //   );
      //   request = this.http.post<void>(url, null, {
      //     headers,
      //     params,
      //     observe: 'response'
      //   });
      // } else
      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources',
          'values/add',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.post<void>(url, null, {
          headers,
          params,
          observe: 'response',
        });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources',
          'values/add',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.post<void>(url, null, {
          headers,
          params,
          withCredentials: true,
          observe: 'response',
        });
      }

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.addResourceValue(
                  id,
                  attributeName,
                  valuesToAdd,
                  adminMode
                );
              })
            );
          } else {
            if (err.statusText === 'OK') {
              return of(null);
            } else {
              return throwError(err);
            }
          }
        })
      );
    }
  }

  public removeResourceValue(
    id: string,
    attributeName: string,
    valuesToRemove: string[] = [],
    adminMode = false,
    simulationID = '',
    simulationActor = ''
  ) {
    if (!id) {
      return throwError('id is missing');
    }
    if (!attributeName) {
      return throwError('attribute name is missing');
    }
    if (!valuesToRemove || valuesToRemove.length === 0) {
      return throwError('values to remove is missing');
    }

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        `resources/multivalued/multiple/${id}/${attributeName}`
      );

      let params: HttpParams;
      if (simulationID) {
        params = new HttpParams({
          fromObject: {
            SimulationSessionId: simulationID,
            SimulationActorId: simulationActor ? simulationActor : '',
          },
        });
      }

      return params
        ? this.http.request('DELETE', url, {
            params,
            body: valuesToRemove,
            observe: 'response',
          })
        : this.http.request('DELETE', url, {
            body: valuesToRemove,
            observe: 'response',
          });
    } else {
      const params: HttpParams = new HttpParams({
        fromObject: {
          id,
          attributeName,
          valuesToRemove: valuesToRemove.join(','),
        },
      });
      let request: Observable<HttpResponse<void>>;

      // if (adminMode === true) {
      //   const url = this.utils.buildDataServiceUrl(
      //     this.baseUrl,
      //     'admin/resources',
      //     'values/remove',
      //     this.serviceType
      //   );
      //   const headers: HttpHeaders = new HttpHeaders().append(
      //     'secret',
      //     this.secret
      //   );
      //   request = this.http.post<void>(url, null, {
      //     headers,
      //     params,
      //     observe: 'response'
      //   });
      // } else
      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources',
          'values/remove',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.post<void>(url, null, {
          headers,
          params,
          observe: 'response',
        });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources',
          'values/remove',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.post<void>(url, null, {
          headers,
          params,
          withCredentials: true,
          observe: 'response',
        });
      }

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.removeResourceValue(
                  id,
                  attributeName,
                  valuesToRemove,
                  adminMode
                );
              })
            );
          } else {
            if (err.statusText === 'OK') {
              return of(null);
            } else {
              return throwError(err);
            }
          }
        })
      );
    }
  }

  public approve(
    id: string,
    approve: boolean,
    reason: string,
    delegator = '',
    isEmergency = false,
    adminMode = false
  ): Observable<void> {
    if (!id) {
      return throwError('id is missing');
    }

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        `approval/${id}`
      );

      const params = new HttpParams({
        fromObject: {
          decision: approve ? 'Approved' : 'Rejected',
          reason,
          onBehalfOfUserId: delegator,
          isEmergencyResponse: isEmergency,
        },
      });

      return this.http.post<void>(url, null, { params });
    } else {
      const params: HttpParams = new HttpParams({
        fromObject: {
          reason,
        },
      });
      let request: Observable<void>;

      // if (adminMode === true) {
      //   const url = this.utils.buildDataServiceUrl(
      //     this.baseUrl,
      //     'admin/resources',
      //     `approve/${id}/${approve}`,
      //     this.serviceType
      //   );
      //   const headers: HttpHeaders = new HttpHeaders().append(
      //     'secret',
      //     this.secret
      //   );
      //   request = this.http.post<void>(url, { headers, params });
      // } else
      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources',
          `approve/${id}/${approve}`,
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.post<void>(url, null, { headers, params });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources',
          `approve/${id}/${approve}`,
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.post<void>(url, null, {
          headers,
          params,
          withCredentials: true,
        });
      }

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.approve(id, approve, reason, '', adminMode);
              })
            );
          } else {
            return throwError(err);
          }
        })
      );
    }
  }

  public responseManualTask(
    id: string,
    data = {},
    reason = '',
    delegator = '',
    isEmergency = false
  ): Observable<void> {
    if (!id) {
      return throwError('id is missing');
    }

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        `manualtask/${id}`
      );

      const params = new HttpParams({
        fromObject: {
          reason,
          onBehalfOfUserId: delegator,
          isEmergencyResponse: isEmergency,
        },
      });

      return this.http.post<void>(url, data, { params });
    }
  }

  public lookup(text: string) {
    let result = text;

    // replace * with %
    result = result.replace(new RegExp(`/\\*`, 'gi'), '!sub!');
    result = result.replace(new RegExp(`\\*`, 'gi'), '%');
    result = result.replace(new RegExp(`!sub!`, 'gi'), '/*');

    // resolve [//loginUser]
    result = result.replace(
      new RegExp(`\\[\\/\\/loginuser\\]`, 'gi'),
      this.loginUser ? this.utils.ExtraValue(this.loginUser, 'ObjectID') : ''
    );

    // resolve [#loginID]
    result = this.resolveLoginID(result);

    // resolve <today()>
    const re = new RegExp(`\\<today\\(\\)[+-]?\\d*\\>`, 'gi');
    let m: any;
    do {
      // eslint-disable-next-line prefer-const
      m = re.exec(result);
      if (m && m.length > 0) {
        const param = m[0].substring(8, m[0].length - 1);
        const dt = param ? moment().add(+param, 'd') : moment();
        result = result.replace(m[0], dt.format('YYYY-MM-DDT00:00:00'));
      }
    } while (m);

    return result;
  }

  public getTypes(
    culture: string,
    adminMode = false
  ): Observable<{ [id: string]: TypeResource }> {
    if (!culture) {
      return throwError('culture is missing');
    }

    let request: Observable<{ [id: string]: TypeResource }>;

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        'resources/search'
      );
      const params: HttpParams = new HttpParams({
        fromObject: {
          xPathQuery: `/ObjectTypeDescription`,
          attributes: 'name,description,displayname',
          pageSize: String(500),
        },
      });
      return this.http.get<ResourceSet>(url, { params }).pipe(
        switchMap((result: ResourceSet) => {
          const dic: { [id: string]: TypeResource } = {};
          if (result && result.results) {
            result.results.forEach((element) => {
              dic[element.name] = {
                description: element.description,
                displayName: element.displayname,
                systemName: element.name,
              };
            });
          }
          return of(dic);
        })
      );
    } else {
      const params: HttpParams = new HttpParams({
        fromObject: { culture },
      });

      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources/schema',
          'types',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<{ [id: string]: TypeResource }>(url, {
          headers,
          params,
        });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources/schema',
          'types',
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<{ [id: string]: TypeResource }>(url, {
          headers,
          params,
          withCredentials: true,
        });
      }

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.getTypes(culture, adminMode);
              })
            );
          } else {
            return throwError(err);
          }
        })
      );
    }
  }

  public getType(
    typeName: string,
    culture: string,
    adminMode = false
  ): Observable<{ [id: string]: AttributeResource }> {
    if (!typeName) {
      return throwError('type is missing');
    }
    if (!culture) {
      return throwError('culture is missing');
    }

    let request: Observable<{ [id: string]: AttributeResource }>;

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        `schema/${typeName}`
      );
      return this.http.get<any>(url).pipe(
        switchMap((result: any) => {
          const dic: { [id: string]: AttributeResource } = {};
          if (result && result.bindings) {
            for (const [key, value] of Object.entries(result.bindings)) {
              dic[key] = {
                description: (value as any).description,
                displayName: (value as any).displayName,
                multivalued: (value as any).multivalued,
                required: (value as any).required,
                stringRegex: (value as any).stringRegex,
                integerMinimum: (value as any).integerMinimum,
                integerMaximum: (value as any).integerMaximum,
                systemName: (value as any).systemName,
                dataType: (value as any).dataType,
                permissionHint: (value as any).permissionHint,
              };
            }
          }
          return of(dic);
        })
      );
    } else {
      const params: HttpParams = new HttpParams({
        fromObject: { culture },
      });

      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources/schema',
          typeName,
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<{ [id: string]: AttributeResource }>(url, {
          headers,
          params,
        });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources/schema',
          typeName,
          this.serviceType
        );
        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<{ [id: string]: AttributeResource }>(url, {
          headers,
          params,
          withCredentials: true,
        });
      }

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.getType(typeName, culture, adminMode);
              })
            );
          } else {
            return throwError(err);
          }
        })
      );
    }
  }

  public getAttribute(
    typeName: string,
    attributeName: string,
    culture: string,
    adminMode = false
  ): Observable<AttributeResource> {
    if (!typeName) {
      return throwError('type is missing');
    }
    if (!attributeName) {
      return throwError('attribute is missing');
    }
    if (!culture) {
      return throwError('culture is missing');
    }

    let request: Observable<AttributeResource>;

    if (this.authNMode === AuthMode.azure) {
      const url = this.utils.buildDataServiceUrl(
        this.baseUrl,
        `schema/${typeName}`
      );
      return this.http.get<any>(url).pipe(
        switchMap((result: any) => {
          let dic: AttributeResource = {};
          const attName = attributeName.toLowerCase();
          if (
            result &&
            result.bindings &&
            result.bindings.hasOwnProperty(attName)
          ) {
            dic = {
              description: result.bindings[attName].description,
              displayName: result.bindings[attName].displayName,
              multivalued: result.bindings[attName].multivalued,
              required: result.bindings[attName].required,
              stringRegex: result.bindings[attName].stringRegex,
              integerMinimum: result.bindings[attName].integerMinimum,
              integerMaximum: result.bindings[attName].integerMaximum,
              systemName: result.bindings[attName].systemName,
              dataType: result.bindings[attName].dataType,
              permissionHint: result.bindings[attName].permissionHint,
            };
          }
          return of(dic);
        })
      );
    } else {
      const params: HttpParams = new HttpParams({
        fromObject: { culture },
      });

      if (this.connection) {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'basic/resources/schema',
          `${typeName}/${attributeName}`,
          this.serviceType
        );

        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<AttributeResource>(url, { headers, params });
      } else {
        const url = this.utils.buildDataServiceUrl(
          this.baseUrl,
          'win/resources/schema',
          `${typeName}/${attributeName}`,
          this.serviceType
        );

        const headers: HttpHeaders = new HttpHeaders().append(
          'token',
          this.token
        );
        request = this.http.get<AttributeResource>(url, {
          headers,
          params,
          withCredentials: true,
        });
      }

      return request.pipe(
        catchError((err) => {
          if (err.status === 409) {
            return this.acquireToken().pipe(
              switchMap(() => {
                return this.getAttribute(
                  typeName,
                  attributeName,
                  culture,
                  adminMode
                );
              })
            );
          } else {
            return throwError(err);
          }
        })
      );
    }
  }

  public xpathToJson(xpath: string): Observable<any> {
    // prevent plus sign to be encoded to %20 (space sign)
    xpath = xpath.replace(/\+/gi, '&#43;');

    const params: HttpParams = new HttpParams({
      fromObject: {
        xpath,
      },
    });
    const url =
      this.authNMode === AuthMode.azure
        ? this.utils.buildDataServiceUrl(this.baseUrl, 'ui/xpath/tojson')
        : this.utils.buildDataServiceUrl(
            this.baseUrl,
            'general',
            'xpathtojson',
            this.serviceType
          );

    return this.http.get(url, { params });
  }

  public jsonToXPath(json: string): Observable<string> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        json,
      },
    });
    const url =
      this.authNMode === AuthMode.azure
        ? this.utils.buildDataServiceUrl(this.baseUrl, 'ui/xpath/toxpath')
        : this.utils.buildDataServiceUrl(
            this.baseUrl,
            'general',
            'jsontoxpath',
            this.serviceType
          );

    return this.authNMode === AuthMode.azure
      ? this.http.get(url, { params, responseType: 'text' })
      : this.http.get<string>(url, { params });
  }

  public getValidationInfo(
    keyword: string,
    currentValue: string = undefined
  ): Observable<ExpressionValidationSpecification> {
    const url = this.utils.buildDataServiceUrl(this.baseUrl, 'ui/validation');
    const params = currentValue
      ? { propertyKey: keyword, currentValue }
      : { propertyKey: keyword };
    return this.http.get<ExpressionValidationSpecification>(url, {
      params,
    });
  }

  public validateExpression(
    config: ExpressionValidationConfig
  ): Observable<ExpressionValidationResult> {
    const url = this.utils.buildDataServiceUrl(this.baseUrl, 'ui/validation');
    return this.http.post<ExpressionValidationResult>(url, config);
  }

  public validateViaApi(
    config: ApiValidationConfig
  ): Observable<ExpressionValidationResult> {
    const url = this.utils.buildDataServiceUrl(this.baseUrl, config.url);
    switch (config.method) {
      case 'GET':
        return this.http.get<ExpressionValidationResult>(url, {
          params: config.params,
        });
      case 'POST':
        return this.http.post<ExpressionValidationResult>(url, config.body, {
          params: config.params,
        });
      default:
        return of({ isValid: false, explanation: 'key_unknownError' });
    }
  }

  public getEventHistory(
    targetId: string,
    count: number,
    startTime: string,
    endTime: string,
    simulationID = ''
  ): Observable<Array<any>> {
    const url = this.utils.buildDataServiceUrl(this.baseUrl, 'event/search');
    const params: HttpParams = new HttpParams({
      fromObject: {
        pageSize: String(count),
        eventTypes: 'resource',
        targetId,
        statuses: 'Success,PostProcessingFailed,PostProcessing',
        startTimeMin: startTime,
        startTimeMax: endTime,
        orderBy: 'CommittedTime',
        simulationSessionId: simulationID,
        flushCache: 'false',
        includeEventArchive: 'true',
      },
    });
    return this.http.get<Array<any>>(url, { params });
  }

  public searchEvents(
    count: number,
    options: {
      eventTypes?: string;
      statuses?: string;
      requestorId?: string;
      targetId?: string;
      workflowId?: string;
      triggerId?: string;
      startTimeMin?: string;
      startTimeMax?: string;
      simulationID?: string;
    }
  ): Observable<ResourceSet> {
    const url = this.utils.buildDataServiceUrl(this.baseUrl, 'event/search');
    const eventTypes = options.eventTypes || '';
    const statuses = options.statuses || '';
    const requestorId = options.requestorId || '';
    const targetId = options.targetId || '';
    const workflowId = options.workflowId || '';
    const triggerId = options.triggerId || '';
    const startTimeMin = options.startTimeMin || '';
    const startTimeMax = options.startTimeMax || '';
    const simulationID = options.simulationID || '';
    const params: HttpParams = new HttpParams({
      fromObject: {
        number: String(count),
        eventTypes,
        statuses,
        requestorId,
        targetId,
        workflowId,
        triggerId,
        startTimeMin,
        startTimeMax,
        simulationSessionId: simulationID,
      },
    });
    return this.http.get<ResourceSet>(url, { params });
  }

  public getResourceHistory(
    targetId: string,
    dateTime: string,
    simulationID = '',
    simulationActor = ''
  ): Observable<any> {
    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      `resources/past/${targetId}`
    );
    const params: HttpParams = new HttpParams(
      simulationID
        ? {
            fromObject: {
              dateTime,
              multiValueThreshold: '100',
              SimulationSessionId: simulationID,
              SimulationActorId: simulationActor,
            },
          }
        : {
            fromObject: {
              dateTime,
              multiValueThreshold: '100',
            },
          }
    );
    return this.http.get<any>(url, { params });
  }

  public getDeletedHistory(
    targetId: string,
    simulationID = '',
    simulationActor = ''
  ): Observable<any> {
    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      `resources/past/deleted/${targetId}`
    );

    const params = simulationID
      ? new HttpParams({
          fromObject: {
            SimulationSessionId: simulationID,
            SimulationActorId: simulationActor ? simulationActor : '',
            includeEventArchive: 'true',
          },
        })
      : null;

    return params
      ? this.http.get<any>(url, { params })
      : this.http.get<any>(url);
  }

  public getRootEvent(eventId: string): Observable<string> {
    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      `event/${eventId}/root`
    );
    return this.http.get<string>(url);
  }

  public getPathEvents(eventId: string): Observable<Array<any>> {
    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      `event/${eventId}/path`
    );
    return this.http.get<any>(url);
  }

  public getEvent(
    eventId: string,
    includeChildEvents: boolean,
    recursive: boolean,
    maxNumber = 500
  ): Observable<any> {
    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      `event/${eventId}`
    );
    const params: HttpParams = new HttpParams({
      fromObject: {
        includeChildEvents: String(includeChildEvents),
        recursive: String(recursive),
        maxNum: String(maxNumber),
        includeEventArchive: 'true',
      },
    });
    return this.http.get<any>(url, { params });
  }

  public getRecentEvents(
    count: number,
    eventTypes: string,
    simulationID = ''
  ): Observable<ResourceSet> {
    const url = this.utils.buildDataServiceUrl(this.baseUrl, `event/search`);
    const params: HttpParams = new HttpParams({
      fromObject: {
        pageSize: String(count),
        eventTypes,
        orderBy: 'CreationTime',
        sortOrder: 'Descending',
        slimFormat: 'true',
        simulationSessionId: simulationID,
        includeRealEvents: 'true',
      },
    });
    return this.http.get<any>(url, { params });
  }

  public getChildEvents(
    parentId: string,
    simulationID = ''
  ): Observable<Array<any>> {
    const url = this.utils.buildDataServiceUrl(this.baseUrl, `event/search`);
    const params: HttpParams = new HttpParams({
      fromObject: {
        pageSize: '100',
        parentId,
        slimFormat: 'true',
        simulationSessionId: simulationID,
      },
    });
    return this.http.get<ResourceSet>(url, { params }).pipe(
      switchMap((result: ResourceSet) => {
        if (result && result.results && result.results.length > 0) {
          return of(result.results);
        } else {
          return of([]);
        }
      })
    );

    // const url = this.utils.buildDataServiceUrl(
    //   this.baseUrl,
    //   `event/${eventId}`
    // );
    // const params: HttpParams = new HttpParams({
    //   fromObject: {
    //     includeChildEvents: 'true',
    //     recursive: 'false',
    //     slimFormat: 'true',
    //   },
    // });
    // return this.http.get<Array<any>>(url, { params }).pipe(
    //   switchMap((result: Array<any>) => {
    //     if (result && result.length > 0) {
    //       result.splice(0, 1);
    //       return of(result);
    //     } else {
    //       return of(result);
    //     }
    //   })
    // );
  }

  public getTypeDisplayName(typeName: string, forceReturn = false): string {
    if (typeName && this.schema) {
      const posType = Object.keys(this.schema).findIndex(
        (key: string) => key.toLowerCase() === typeName.toLowerCase()
      );
      if (posType >= 0) {
        const typeDesc = this.schema[Object.keys(this.schema)[posType]];
        if (typeDesc.displayName) {
          return typeDesc.displayName;
        }
      }
    }

    return forceReturn ? typeName : null;
  }

  public getAttributeDisplayName(
    typeName: string,
    attributeName: string,
    forceReturn = false
  ): string {
    if (typeName && this.schema) {
      const posType = Object.keys(this.schema).findIndex(
        (key: string) => key.toLowerCase() === typeName.toLowerCase()
      );
      if (posType >= 0) {
        const typeDesc = this.schema[Object.keys(this.schema)[posType]];
        if (typeDesc && typeDesc.bindings) {
          const posAttribute = Object.keys(typeDesc.bindings).findIndex(
            (key: string) => key.toLowerCase() === attributeName.toLowerCase()
          );
          if (posAttribute >= 0) {
            const bindingDesc =
              typeDesc.bindings[Object.keys(typeDesc.bindings)[posAttribute]];
            if (bindingDesc) {
              return bindingDesc.displayName;
            }
          }
        }
      }
    }

    return forceReturn ? attributeName : null;
  }

  public getNextPage(
    pagingToken: string,
    pageSize: number,
    path = 'resources/search/continue'
  ): Observable<ResourceSet> {
    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      path ? path : 'resources/search/continue'
    );
    const params: HttpParams = new HttpParams({
      fromObject: {
        continuationToken: pagingToken,
        pageSize: pageSize.toString(),
      },
    });
    return this.http.get<ResourceSet>(url, { params });
  }

  public getSystemMessage(): Observable<string> {
    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      'internal/systemmessage'
    );
    return this.http.get(url, { responseType: 'text' }).pipe(
      catchError((err) => {
        return of('');
      })
    );
  }

  public refreshPagingTokens(tokens: Array<RefreshToken>): Observable<any[]> {
    if (tokens && tokens.length > 0) {
      const groupedRefreshToken = this.utils.groupBy(tokens, 'name');
      const observableBatch = [];
      Object.keys(groupedRefreshToken).forEach((key: string) => {
        const tks = groupedRefreshToken[key].map((t: any) => t.token);
        const url = this.utils.buildDataServiceUrl(this.baseUrl, key);
        observableBatch.push(this.http.post<string[]>(url, tks));
      });
      return forkJoin<any[]>(observableBatch);
    }
    return of([]);
  }

  public startSimulation(): Observable<string> {
    const url = this.utils.buildDataServiceUrl(this.baseUrl, `simulation`);
    return this.http.post<string>(url, null);
  }

  public stopSimulation(simulationID: string): Observable<void> {
    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      `simulation/${simulationID}`
    );
    return this.http.delete<void>(url);
  }

  public executeWorkflow(
    method: string,
    mode: string,
    targetIDs: Array<string>,
    targetXPath: string,
    workflowID: string
  ): Observable<any> {
    if (!workflowID) {
      return throwError('workflowTemplateID is missing');
    }

    const path = `workflow/execute/${method}/${mode}`;
    const url = this.utils.buildDataServiceUrl(this.baseUrl, path);

    const params =
      method === 'xpath'
        ? new HttpParams({
            fromObject: {
              workflowId: workflowID,
              targetXpath: targetXPath,
            },
          })
        : new HttpParams({
            fromObject: { workflowId: workflowID },
          });

    return this.http.patch<any>(url, method === 'xpath' ? null : targetIDs, {
      params,
    });
  }

  public executeWorkflowTemplate(
    method: string,
    mode: string,
    targetIDs: Array<string>,
    targetXPath: string,
    workflowTemplateID: string,
    inputs: any
  ): Observable<any> {
    if (!workflowTemplateID) {
      return throwError('workflowTemplateID is missing');
    }

    const path = `workflow/execute/template/${method}/${mode}`;
    const url = this.utils.buildDataServiceUrl(this.baseUrl, path);

    let params = new HttpParams({
      fromObject: { workflowTemplateId: workflowTemplateID },
    });
    if (method === 'xpath') {
      params = params.append('targetXpath', targetXPath);
    } else if (targetIDs && targetIDs.length > 0) {
      targetIDs.forEach((id) => {
        params = params.append('targetIds', id);
      });
    }

    return this.http.patch<any>(url, inputs, {
      params,
    });
  }

  public restoreResource(
    id: string,
    time: string,
    simulationID = '',
    simulationActor = ''
  ): Observable<HttpResponse<any>> {
    if (!id) {
      return throwError('id is missing');
    }
    if (!time) {
      return throwError('time is missing');
    }

    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      'resources/restore',
      id
    );
    const params = simulationID
      ? new HttpParams({
          fromObject: {
            dateTime: time,
            disablePostUpdateTriggers: 'true',
            SimulationSessionId: simulationID,
            SimulationActorId: simulationActor ? simulationActor : '',
          },
        })
      : new HttpParams({
          fromObject: {
            dateTime: time,
            disablePostUpdateTriggers: 'true',
          },
        });

    return this.http.patch<HttpResponse<any>>(url, null, {
      params,
      observe: 'response',
    });
  }

  public cancelRequest(
    id: string,
    timeoutSeconds = 60
  ): Observable<HttpResponse<any>> {
    if (!id) {
      return throwError('id is missing');
    }

    const params = new HttpParams({
      fromObject: {
        timeoutSeconds,
      },
    });

    const url = this.utils.buildDataServiceUrl(this.baseUrl, 'workflow/cancel');

    return this.http.post<HttpResponse<any>>(url, [id], {
      params,
      observe: 'response',
    });
  }

  public resumeRequest(
    id: string,
    replaceInstance = true
  ): Observable<HttpResponse<any>> {
    if (!id) {
      return throwError('id is missing');
    }

    const url = this.utils.buildDataServiceUrl(this.baseUrl, 'workflow/resume');

    const params = new HttpParams({
      fromObject: {
        workflowEventId: id,
        replaceWorkflowInstanceWithCurrentDefinition: String(replaceInstance),
      },
    });

    return this.http.post<HttpResponse<any>>(url, null, {
      params,
      observe: 'response',
    });
  }

  public revertRequest(
    id: string,
    simulationID = '',
    simulationActor = ''
  ): Observable<HttpResponse<any>> {
    if (!id) {
      return throwError('id is missing');
    }

    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      'resources/revert',
      id
    );

    const headers: HttpHeaders = new HttpHeaders({
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Expose-Headers': 'X-Idabus-Event-Id',
    });

    const params = simulationID
      ? new HttpParams({
          fromObject: {
            SimulationSessionId: simulationID,
            SimulationActorId: simulationActor ? simulationActor : '',
          },
        })
      : undefined;

    return this.http.patch<HttpResponse<any>>(
      url,
      null,
      params
        ? { headers, params, observe: 'response' }
        : { headers, observe: 'response' }
    );
  }

  public recalculateDataFlowRule(
    id: string,
    simulationID = '',
    simulationActor = ''
  ): Observable<HttpResponse<any>> {
    if (!id) {
      return throwError('id is missing');
    }

    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      'workflow/recomputeDataflow'
    );

    const params = simulationID
      ? new HttpParams({
          fromObject: {
            SimulationSessionId: simulationID,
            SimulationActorId: simulationActor ? simulationActor : '',
          },
        })
      : new HttpParams({
          fromObject: {
            ruleId: id,
          },
        });

    return this.http.post<HttpResponse<any>>(url, null, {
      params,
      observe: 'response',
    });
  }

  public executeExpression(expressionBody: any): Observable<any> {
    const path = `internal/executeexpression`;
    const url = this.utils.buildDataServiceUrl(this.baseUrl, path);

    const params: HttpParams = new HttpParams({
      fromObject: {
        allowFunctionExpressions: 'true',
        allowXPath: 'false',
        allowInterpolatedStrings: 'false',
      },
    });

    return this.http.post(url, expressionBody, {
      params,
      observe: 'response',
      responseType: 'text',
    });
  }

  public isWorkflowDetailMessageEnabled(id: string): Observable<boolean> {
    if (!id) {
      return throwError('id is missing');
    }

    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      `workflow/detailedMessagesEnabled/${id}`
    );

    return this.http.get<boolean>(url);
  }

  public enableWorkflowDetailmessage(
    ids: Array<string>
  ): Observable<HttpResponse<any>> {
    if (!ids) {
      return throwError('ids are missing');
    }

    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      `workflow/detailedMessagesEnabled`
    );

    return this.http.patch<HttpResponse<any>>(url, ids);
  }

  public disableWorkflowDetailmessage(
    ids: Array<string>
  ): Observable<HttpResponse<any>> {
    if (!ids) {
      return throwError('ids are missing');
    }

    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      `workflow/detailedMessagesEnabled`
    );

    return this.http.delete<HttpResponse<any>>(url, { body: ids });
  }

  public getTriggerRunInfo(id: string): Observable<HttpResponse<any>> {
    if (!id) {
      return throwError('id is missing');
    }

    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      `workflow/trigger/runinfo`
    );

    return this.http.get<HttpResponse<any>>(url, { params: { triggerId: id } });
  }

  public resetTriggerRuns(id: string): Observable<HttpResponse<void>> {
    if (!id) {
      return throwError('id is missing');
    }

    const url = this.utils.buildDataServiceUrl(
      this.baseUrl,
      `workflow/trigger/reset`
    );

    return this.http.get<HttpResponse<void>>(url, {
      params: { triggerId: id },
    });
  }

  public updateUISettings(): Observable<any> {
    const compressedUiSetting = `-ZipB64-${this.utils.zipString(
      this.utils.stringifyComponentConfig(this.primaryViewSetting)
    )}`;
    this.primaryViewSet[this.utils.attConfiguration] = compressedUiSetting;
    this.primaryViewSet[this.utils.attObjectStatus] = moment().format();

    return this.getResourceByID(
      this.utils.ExtraValue(this.primaryViewSet, 'ObjectID'),
      [this.utils.attObjectStatus]
    ).pipe(
      switchMap((currentViewSet: Resource) => {
        const strFetchedTimestamp =
          this.authNMode === AuthMode.azure
            ? currentViewSet[this.utils.attObjectStatus.toLowerCase()]
            : currentViewSet[this.utils.attObjectStatus];
        // const strFetchedTimestamp = this.utils.ExtraValue(
        //   currentViewSet,
        //   this.utils.attObjectStatus
        // );
        if (!strFetchedTimestamp || !this.primaryViewTimestamp) {
          return this.updateResource(this.primaryViewSet);
        } else {
          const fetchedTimestamp = moment(strFetchedTimestamp);
          const storedTimestamp = moment(this.primaryViewTimestamp);
          if (fetchedTimestamp.isAfter(storedTimestamp)) {
            return of('expired');
          } else {
            return this.updateResource(this.primaryViewSet).pipe(
              tap(() => {
                this.primaryViewTimestamp = moment().format();
              })
            );
          }
        }
      })
    );
  }

  public updateCustomSettings(): Observable<any> {
    const id = this.utils.ExtraValue(this.loginUser, 'ObjectID');
    if (id) {
      const objectToSave = {
        ObjectID: id,
      };
      objectToSave[
        this.utils.attConfiguration
      ] = `-ZipB64-${this.utils.zipString(
        JSON.stringify(this.customViewSetting)
      )}`;
      return this.updateResource(objectToSave);
    } else {
      return throwError(new Error('No login user ID was found'));
    }
  }

  public isUISettingsUpToDate(): Observable<string> {
    return this.getResourceByID(
      this.utils.ExtraValue(this.primaryViewSet, 'ObjectID'),
      [this.utils.attObjectStatus]
    ).pipe(
      switchMap((currentViewSet: Resource) => {
        const strFetchedTimestamp =
          this.authNMode === AuthMode.azure
            ? currentViewSet[this.utils.attObjectStatus.toLowerCase()]
            : currentViewSet[this.utils.attObjectStatus];
        if (!strFetchedTimestamp || !this.primaryViewTimestamp) {
          return of(null);
        } else {
          const fetchedTimestamp = moment(strFetchedTimestamp);
          const storedTimestamp = moment(this.primaryViewTimestamp);
          if (fetchedTimestamp.isAfter(storedTimestamp)) {
            return of('expired');
          } else {
            return of(null);
          }
        }
      })
    );
  }

  // help functions

  public loginUserIsAdmin(): boolean {
    if (this.rightSets && this.rightSets.length > 0) {
      const adminSetNames = this.config.getConfig('adminSetName', [
        'Administrators',
      ]);
      return this.rightSets.some((r) => adminSetNames.indexOf(r) >= 0);
    }
    return false;
  }

  public inPermissionSets(permissions: Array<string>): boolean {
    if (!permissions || !permissions.length || permissions.length === 0) {
      return true;
    }
    if (permissions && permissions.length > 0) {
      return this.rightSets.some((r) => permissions.indexOf(r) >= 0);
    }
    return false;
  }

  public resolveLoginID(query: string) {
    let retVal = '';
    if (query) {
      retVal = query.replace(
        /\[#LoginID\]/gi,
        this.utils.ExtraValue(this.loginUser, 'ObjectID')
      );
    }
    return retVal;
  }

  /**
   * Register refresh token for paging from grid component
   * @param token Refresh token to register
   */
  registerRefreshToken(rt: RefreshToken) {
    if (this.refreshTokens.findIndex((r) => r.token === rt.token) < 0) {
      this.refreshTokens.push(rt);
    }
  }

  /**
   * Remove refresh token
   * @param token Refresh token to remove
   */
  removeRefreshToken(token: any) {
    if (typeof token === 'string') {
      const index = this.refreshTokens.findIndex((r) => r.token === token);
      if (index >= 0) {
        this.refreshTokens.splice(index, 1);
      }
    } else if (Array.isArray(token)) {
      token.forEach((t: string) => {
        const index = this.refreshTokens.findIndex((r) => r.token === t);
        if (index >= 0) {
          this.refreshTokens.splice(index, 1);
        }
      });
    }
  }

  /**
   * Start a timer to send refresh token request
   * @param duration Time interval to send the refresh token request
   */
  startRefreshToken(duration: number) {
    return timer(0, duration).pipe(
      switchMap(() => {
        return this.refreshPagingTokens(this.refreshTokens);
      }),
      tap((result: any[]) => {
        if (result && result.length > 0) {
          result.forEach((t) => {
            this.removeRefreshToken(t);
          });
        }
      })
    );
  }

  startApiTimeRecording(duration: number) {
    return timer(0, duration).pipe(
      switchMap(() => {
        return of(moment().diff(this.apiActivationTime));
      })
    );
  }
}
