import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ModalType } from 'src/app/core/models/componentContract.model';
import { WorkflowActivityView } from 'src/app/core/models/dynamicEditor.interface';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-activity-rest-api-call',
  templateUrl: './activity-rest-api-call.component.html',
  styleUrls: ['./activity-rest-api-call.component.scss'],
})
export class ActivityRestApiCallComponent
  extends WorkflowActivityView
  implements OnInit
{
  actionMethod: string;
  apiIsAsync: boolean;
  authType: string;
  authTargetID: string;
  authClientId: string;
  authClientSecret: string;
  authScope: string;
  authTokenEndpoint: string;
  authAzureTenantId: string;
  apiContentType: string;
  url: string;
  responseKeyWord: string;
  headerExp: any;
  queryExp: Array<{ key: string; value: string }>;
  bodyExp: any;
  responseCodes: Array<number>;

  headerKey: string;
  headerValue: string;
  headerDic: Array<{ key: string; value: string }> = [];

  queryKey: string;
  queryValue: string;

  expectedCode: number;

  showHeaders = false;
  showQueries = false;
  showBody = false;
  showResponseCodes = false;

  selectedContentType: string;
  availableContentTypes = [
    'application/json',
    'application/xml',
    'text/plain',
    'application/x-www-form-urlencoded',
  ];

  @Input()
  get method() {
    return this.actionMethod;
  }
  set method(value: string) {
    this.actionMethod = value;
    this.methodChange.emit(this.actionMethod);
  }
  @Output()
  methodChange = new EventEmitter();

  @Input()
  get asyncCallBack() {
    return this.apiIsAsync;
  }
  set asyncCallBack(value: boolean) {
    this.apiIsAsync = value;
    this.asyncCallBackChange.emit(this.apiIsAsync);
  }
  @Output()
  asyncCallBackChange = new EventEmitter();

  @Input()
  get authenticationType() {
    return this.authType;
  }
  set authenticationType(value: string) {
    this.authType = value;
    this.authenticationTypeChange.emit(this.authType);
  }
  @Output()
  authenticationTypeChange = new EventEmitter();

  @Input()
  get azureResourceId() {
    return this.authTargetID;
  }
  set azureResourceId(value: string) {
    this.authTargetID = value;
    this.azureResourceIdChange.emit(this.authTargetID);
  }
  @Output()
  azureResourceIdChange = new EventEmitter();

  @Input()
  get oauth2ClientId() {
    return this.authClientId;
  }
  set oauth2ClientId(value: string) {
    this.authClientId = value;
    this.oauth2ClientIdChange.emit(this.authClientId);
  }
  @Output()
  oauth2ClientIdChange = new EventEmitter();

  @Input()
  get oauth2ClientSecret() {
    return this.authClientSecret;
  }
  set oauth2ClientSecret(value: string) {
    this.authClientSecret = value;
    this.oauth2ClientSecretChange.emit(this.authClientSecret);
  }
  @Output()
  oauth2ClientSecretChange = new EventEmitter();

  @Input()
  get oauth2Scope() {
    return this.authScope;
  }
  set oauth2Scope(value: string) {
    this.authScope = value;
    this.oauth2ScopeChange.emit(this.authScope);
  }
  @Output()
  oauth2ScopeChange = new EventEmitter();

  @Input()
  get oauth2TokenEndpoint() {
    return this.authTokenEndpoint;
  }
  set oauth2TokenEndpoint(value: string) {
    this.authTokenEndpoint = value;
    this.oauth2TokenEndpointChange.emit(this.authTokenEndpoint);
  }
  @Output()
  oauth2TokenEndpointChange = new EventEmitter();

  @Input()
  get azureTenantId() {
    return this.authAzureTenantId;
  }
  set azureTenantId(value: string) {
    this.authAzureTenantId = value;
    this.azureTenantIdChange.emit(this.authAzureTenantId);
  }
  @Output()
  azureTenantIdChange = new EventEmitter();

  @Input()
  get urlExpression() {
    return this.url;
  }
  set urlExpression(value: string) {
    this.url = value;
    this.urlExpressionChange.emit(this.url);
  }
  @Output()
  urlExpressionChange = new EventEmitter();

  @Input()
  get contentType() {
    return this.apiContentType;
  }
  set contentType(value: string) {
    this.apiContentType = value;
    this.contentTypeChange.emit(this.apiContentType);
  }
  @Output()
  contentTypeChange = new EventEmitter();

  @Input()
  get responseKey() {
    return this.responseKeyWord;
  }
  set responseKey(value: string) {
    this.responseKeyWord = value;
    this.responseKeyChange.emit(this.responseKeyWord);
  }
  @Output()
  responseKeyChange = new EventEmitter();

  @Input()
  get headerExpression() {
    return this.headerExp;
  }
  set headerExpression(value: any) {
    this.headerExp = value;
    this.headerExpressionChange.emit(this.headerExp);
  }
  @Output()
  headerExpressionChange = new EventEmitter();

  @Input()
  get queryExpression() {
    return this.queryExp;
  }
  set queryExpression(value: Array<{ key: string; value: string }>) {
    this.queryExp = value;
    this.queryExpressionChange.emit(this.queryExp);
  }
  @Output()
  queryExpressionChange = new EventEmitter();

  @Input()
  get bodyExpression() {
    return this.bodyExp;
  }
  set bodyExpression(value: any) {
    this.bodyExp = value;
    this.bodyExpressionChange.emit(this.bodyExp);
  }
  @Output()
  bodyExpressionChange = new EventEmitter();

  @Input()
  get expectedResponseCodes() {
    return this.responseCodes;
  }
  set expectedResponseCodes(value: Array<number>) {
    this.responseCodes = value;
    this.expectedResponseCodesChange.emit(this.responseCodes);
  }
  @Output()
  expectedResponseCodesChange = new EventEmitter();

  constructor(private modal: ModalService) {
    super();
  }

  ngOnInit(): void {
    if (this.contentType) {
      if (this.availableContentTypes.indexOf(this.contentType) < 0) {
        this.selectedContentType = 'custom';
      } else {
        this.selectedContentType = this.contentType;
      }
    }

    if (this.headerExpression) {
      Object.keys(this.headerExpression).forEach((k: string) => {
        this.headerDic.push({ key: k, value: this.headerExpression[k] });
      });
    }

    setTimeout(() => {
      if (!this.bodyExpression) {
        this.bodyExpression = '';
      }
      if (typeof this.bodyExpression === 'object') {
        this.bodyExpression = JSON.stringify(this.bodyExpression, null, 4);
      }
    });
  }

  onAddHeader() {
    if (!this.headerExpression) {
      this.headerExpression = {};
    } else {
      const pos = this.headerDic.findIndex((item) => {
        return item.key === this.headerKey;
      });
      if (pos >= 0) {
        this.modal.show(ModalType.error, 'key_warning', 'key_headerExist');
        return;
      }
    }

    this.headerExpression[this.headerKey] = this.headerValue;

    this.headerDic.push({
      key: this.headerKey,
      value: this.headerValue,
    });

    this.headerKey = '';
    this.headerValue = '';
  }

  onAddQuery() {
    if (!this.queryExpression) {
      this.queryExpression = [];
    }

    let parsedValue: any = this.queryValue;
    if (
      (this.queryValue.startsWith(`"`) && this.queryValue.endsWith(`"`)) ||
      (this.queryValue.startsWith(`'`) && this.queryValue.endsWith(`'`))
    ) {
      parsedValue = this.queryValue.substr(1, this.queryValue.length - 2);
    } else {
      if (this.queryValue.toLowerCase() === 'true') {
        parsedValue = true;
      } else if (this.queryValue.toLowerCase() === 'false') {
        parsedValue = false;
      } else {
        const intValue = parseInt(this.queryValue, 10);
        if (!isNaN(intValue)) {
          if (String(intValue).length === this.queryValue.length) {
            parsedValue = intValue;
          }
        }
      }
    }

    this.queryExpression.push({
      key: this.queryKey,
      value: parsedValue,
    });

    this.queryKey = '';
    this.queryValue = '';
  }

  onAddExpectedCode() {
    if (!this.expectedResponseCodes) {
      this.expectedResponseCodes = [];
    } else {
      const pos = this.expectedResponseCodes.indexOf(this.expectedCode);
      if (pos >= 0) {
        this.modal.show(
          ModalType.error,
          'key_warning',
          'key_expectedCodeExist'
        );
        return;
      }
    }

    this.expectedResponseCodes.push(this.expectedCode);
    this.expectedCode = undefined;
  }

  onRemoveHeader(key: string) {
    try {
      delete this.headerExpression[key];

      const pos = this.headerDic.findIndex((item) => {
        return item.key === key;
      });
      if (pos >= 0) {
        this.headerDic.splice(pos, 1);
      }
    } catch {}
  }

  onRemoveQuery(key: string) {
    const pos = this.queryExpression.findIndex((item) => {
      return item.key === key;
    });
    if (pos >= 0) {
      this.queryExpression.splice(pos, 1);
    }
  }

  onRemoveExpectedCode(index: number) {
    this.expectedResponseCodes.splice(index, 1);
  }

  onSwitchSetting(setting: string) {
    switch (setting) {
      case 'headers':
        this.showQueries = false;
        this.showBody = false;
        this.showResponseCodes = false;
        this.showHeaders = true;
        this.queryKey = '';
        this.queryValue = '';
        break;
      case 'queries':
        this.showHeaders = false;
        this.showBody = false;
        this.showResponseCodes = false;
        this.showQueries = true;
        this.headerKey = '';
        this.headerValue = '';
        break;
      case 'body':
        this.showHeaders = false;
        this.showQueries = false;
        this.showResponseCodes = false;
        this.showBody = true;
        this.queryKey = '';
        this.queryValue = '';
        this.headerKey = '';
        this.headerValue = '';
        break;
      case 'codes':
        this.showHeaders = false;
        this.showQueries = false;
        this.showBody = false;
        this.showResponseCodes = true;
        this.queryKey = '';
        this.queryValue = '';
        this.headerKey = '';
        this.headerValue = '';
        break;
      default:
        break;
    }
  }

  onContentTypeChange(event: string) {
    if (event !== 'custom') {
      this.contentType = event;
    }
  }
}
